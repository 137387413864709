@font-face {
    font-family: "Lato";
    src: url(/src/res/font/lato-light.ttf);
    font-weight: normal;
}

@font-face {
    font-family: "Lato";
    src: url(/src/res/font/lato-bold.ttf);
    font-weight: bold;
}

body {
    margin: 0;
}

body * {
    font-family: "Lato", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
