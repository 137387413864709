/* padding fix */
.MuiPagination-ul > li > button {
    padding: 0;
}

q {
    quotes: "„" "”";
}

.bold {
    font-weight: bold !important;
}

.italic {
    font-style: italic;
}

.flex-grow {
    flex-grow: 1;
}

.word-link {
    text-decoration: none;
    border-bottom: solid 1px #0000;
    transition: color .15s, text-shadow .15s, border-bottom-color .15s;
}

.word-link:hover, .word-link:active, .word-link:focus {
    color: #2196f3;
    text-shadow: #2196f3 0 0 1px;
    border-bottom-color: #2196f3;
}