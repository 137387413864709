#content-container {
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.nav-link {
    padding: 1rem 0.5rem;
    text-decoration: none;
    border-bottom: 2px solid #0000;
    transition: text-shadow .15s, border-bottom-color .15s;
}

.nav-link:hover, .nav-link:active, .nav-link:focus {
    text-shadow: #fff 0 0 2px;
    border-bottom-color: #fff;
}

nav :last-child {
    padding-right: 0;
}