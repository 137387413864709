.letter-link {
    text-decoration: none;
    border-bottom: solid 1px #0000;
    padding: 0 .25em;
    transition: color .15s, text-shadow .15s, border-bottom-color .15s;
}

.letter-link:hover, .letter-link:active, .letter-link:focus, .letter-link.active {
    color: #ff9800;
    text-shadow: #ff9800 0 0 1px;
    border-bottom-color: #ff9800;
}